import { get, post, ajax_download, download } from "./Service";

export const getUniqueId = (onDone) => {
    get("/api/attachments/get-uuid", (response) => {
        onDone(response.data);
    });
};

export const uploadFile = (file, fileName, guid, menu_id, contextId, context, site_id, onDone) => {
    post(encodeURI("/api/lego/upload-file/" + fileName + "/" + guid + "/" + menu_id + "/" + contextId + "/" + context + "/" + site_id), file, (res) => {
        onDone(res);
    });
};

export const getUploadedFile = ({ guid, contextId, context }, onDone) => {
    get(encodeURI("/api/attachments/get-uploaded-files/" + guid + "/" + contextId + "/" + context), (res) => {
        onDone(res);
    });
};

export const downloadFile = (id, onDone) => {
    ajax_download(encodeURI("/api/lego/download-file/" + id), (res) => {
        onDone(res);
    });
};

export const getImageData = (id, onDone) => {
    get(encodeURI("/api/lego/image-data-url/" + id), (res) => {
        onDone(res);
    });
};

export const downloadReport = (module, type, fileName, payload, onDone) => {
    download(encodeURI("/api/lego/download-report/" + module + "/" + type + "/" + fileName), payload, (res) => {
        serveFile(fileName, res);
        onDone();
    });
};

let serveFile = (fileName, res) => {
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
};
