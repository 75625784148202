import axios from "axios";
import JsFileDownloader from 'js-file-downloader';
import { startLoading, stopLoading } from "../components/Util"

const capturelastActivity = () => {
    localStorage.setItem('last_activity', Date.now());
};

const get = (url, onDone) => {
    capturelastActivity();
    axios({
        method: "get",
        url: url,
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        if (res.data.msg && res.data.msg === 'Invalid token: ExpiredSignature') {
            localStorage.setItem('isTokenExpired', true);
        } else onDone(res.data);
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/login";
            }
        }
    });
};

const post = (url, payload, onDone) => {
    capturelastActivity();
    axios({
        method: "post",
        url: url,
        data: payload,
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        if (res.data.msg && res.data.msg === 'Invalid token: ExpiredSignature') {
            localStorage.setItem('isTokenExpired', true);
        } else onDone(res.data);
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 409) {
                onDone({ status: 'ERROR', data: error.response.data.detail });
            }
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/login";
            }
        }
    });
};

const patch = (url, payload, onDone) => {
    capturelastActivity();
    axios({
        method: "patch",
        url: url,
        data: payload,
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        onDone(res.data);
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 409) {
                onDone({ status: 'ERROR', data: error.response.data.detail });
            }
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/login";
            }
        }
    });
};

const download = (url, payload, onDone) => {
    capturelastActivity();
    axios({
        method: "post",
        url: url,
        data: payload,
        responseType: 'arraybuffer',
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        onDone(res.data);
    }).catch(error => {
        console.log("error: ", error)
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = "/login";
            } else console.log(error);
        }
    });
};

const ajax_download = (url, onDone) => {
    capturelastActivity();
    startLoading();
    new JsFileDownloader({
        url: url,
        headers: [
            { name: 'Authorization', value: "Bearer " + JSON.parse(localStorage.getItem('token')) },
            { name: 'Access-Control-Allow-Origin', value: '*' }
        ]
    })
        .then(function () {
            stopLoading();
            onDone()
        })
        .catch(function (error) {
            stopLoading();
            onDone()
        });
}

export { get, post, patch, download, ajax_download };
