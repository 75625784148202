import { post,get } from "./Service";
import { arrayToMap } from "./../components/Util";

export const getJSON = (payload, onDone) => {
    post("/api/lego/recipe", payload, (res) => {
        onDone(res.data);
    });
};

export const instrument_connection = (payload, onDone) => {
    post("/api/lego/instrument-connection", payload, (res) => {
        onDone(res);
    });
};

export const instrument_ftp_connection = (payload, onDone) => {
    post("/api/lego/machine-connection", payload, (res) => {
        onDone(res);
    });
};

export const fetch_intrument_data = (onDone) => {
    get("/api/lego/fetch-intrument-data", (response) => {
        onDone(response);
    });
};

export const executeQuery = (payload, onDone) => {
    post("/api/lego/query", payload, (res) => {
        if (res && res.status === "SUCCESS") {
            let obj = { data: res.data.result, tabs: res.data.result_tab }
            onDone(obj)
        }
        else if (res && res.status === "ERROR") {
            let obj = { data: [], tabs: [], error: res.msg }
            onDone(obj)
        }
        else {
            let obj = { data: [], tabs: [] }
            onDone(obj)
        }
    });
};

export const setObject = (payload, onDone) => {
    payload.is_email_send = payload.is_email_send ? payload.is_email_send : false;
    post("/api/lego/set-object", payload, (res) => {
        onDone(res);
    });
};

export const approveObject = (payload, onDone) => {
    let site_id = JSON.parse(localStorage.getItem('user')).current_auth.site_id;
    payload.site_id = payload.site_id ? payload.site_id : site_id;
    post("/api/lego/approve-object", payload, (res) => {
        onDone(res);
    });
};

export const approveSample = (payload, onDone) => {
    let site_id = JSON.parse(localStorage.getItem('user')).current_auth.site_id;
    payload.site_id = payload.site_id ? payload.site_id : site_id;
    post("/api/lego/approve-sample", payload, (res) => {
        onDone(res);
    });
};

export const approveExperiment = (payload, onDone) => {
    let site_id = JSON.parse(localStorage.getItem('user')).current_auth.site_id;
    payload.site_id = payload.site_id ? payload.site_id : site_id;
    post("/api/lego/approve-experiment", payload, (res) => {
        onDone(res);
    });
};

export const dispatchEmail = (payload, onDone) => {
    post("/api/lego/dispatch-email", payload, (res) => {
        onDone(res);
    });
};

export const investigate = (payload, onDone) => {
    post("/api/lego/investigation-flow", payload, (res) => {
        onDone(res);
    });
};

export const getSetObject = (payload, onDone) => {
    post("/api/lego/upsert", payload, (res) => {
        onDone(res);
    });
};

export const getNextSequence = (payload, onDone) => {
    post("/api/lego/next-sequence", payload, (res) => {
        onDone(res);
    });
};

export const getLookups = (module_name, operation_type, operation_name, payload, onDone) => {
    post("/api/lego/" + module_name + "/" + operation_type + "/" + operation_name, payload, (res) => {
        if (res && res["Result Set"]) {
            let lookup = res["Result Set"];
            lookup = arrayToMap(lookup, 'family');
            onDone(lookup);
        }
    });
};

export const consignObject = (payload, onDone) => {
    post("/api/lego/consign-object", payload, (res) => {
        onDone(res);
    });
};

export const addTask = (payload, onDone) => {
    post("/api/lego/add-task", payload, (res) => {
        onDone(res);
    });
};

export const deleteAlert = (payload, onDone) => {
    post("/api/lego/delete-alert", payload, (res) => {
        onDone(res);
    });
};

export const deleteTask = (payload, onDone) => {
    post("/api/lego/delete-task", payload, (res) => {
        onDone(res);
    });
};