import Constants from "../actionTypes";
import moment from 'moment';

const initialState = {
    meta: {},
    isAuthenticated: false
};

export const me = (state = initialState, action) => {
    switch (action.type) {
        case Constants.SET_ME:
            if (action.payload.app_configs && action.payload.app_configs.subscription === 'SaaS') {
                let sass_expiry_date = moment(new Date(action.payload.app_configs.saas_expiry_date)).format("yyyy-MM-DD");
                let today = moment(moment(new Date()).format("yyyy-MM-DD"));
                if (moment(moment(sass_expiry_date).format("yyyy-MM-DD")).diff(today, 'days') < 0)
                    localStorage.setItem("sass_expired", true);
            }
            return {
                ...state,
                meta: action.payload.user,
                auths: action.payload.auths,
                current_auth: action.payload.current_auth,
                permissions: action.payload.permissions,
                esign_settings: action.payload.esign_settings,
                security_settings: action.payload.settings,
                app_configs: action.payload.app_configs,
                preferences: action.payload.preferences ? action.payload.preferences[0] : undefined,
                isAuthenticated: action.payload && action.payload.user_name && action.payload.user_name.length > 0
            };
        default:
            return state;
    }
}
